import Vue from "vue";

function getRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("policies");
}

export async function getById(orgId, id) {
  return getRef(orgId)
    .doc(id)
    .get();
}

export async function list(orgId) {
  return getRef(orgId).get();
}

export async function listByParentId(orgId, parentId) {
  return getRef(orgId)
    .where("parentId", "==", parentId)
    .where("status", "==", "Active")
    .get();
}
export async function save(orgId, payload) {
  const obj = Object.assign({}, payload);
  if (obj.children) {
    obj.children = [];
  }
  if (obj.id != undefined) {
    return getRef(orgId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId).add(obj);
  }
}
