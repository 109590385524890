<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-row>
      
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row class="pa-4" justify="space-between">
              <v-col cols="5">
                <v-treeview
               
                  :active.sync="active"
                  :items="items"
                  :load-children="loadChildren"
                  :open.sync="open"
                  activatable
                  color="warning"
                  return-object
                  selection-type="independent"
                  transition
                  item-key="id"
                >
                  <template v-slot:prepend="{ item, active }">
                    <v-icon
                      v-if="item.type=='folder'"
                    >{{ active ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
                    <v-icon v-else>file-document</v-icon>
                  </template>
                </v-treeview>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col class="d-flex">
                <v-scroll-y-transition mode="out-in">
                  <div
                    v-if="!active || active.length==0"
                    class="title grey--text text--lighten-1 font-weight-light"
                    style="align-self: center;"
                  >Select a folder/file</div>

                  <v-card v-else :key="active.id" flat>
                    <v-row class="text-right" tag="v-card-text" v-if="isAdmin">
                      <v-col cols="12">
                        <v-btn @click="dialog=true" v-if="active[0].type=='folder'">Add folder</v-btn>
                        <upload
                          v-if="active[0].type=='folder'"
                          v-bind:path="basePath"
                          v-on:uploadComplete="policiesAndProceduresUploaded"
                          button-text="Upload file"
                          :text="false"
                        ></upload>
                        <v-btn @click="deleteNode()" v-if="active[0].parentId!==1">delete</v-btn>
                      </v-col>
                      <v-divider></v-divider>
                    </v-row>

                    <v-row class="text-left" tag="v-card-text">
                      <v-col cols="12" v-if="active[0].type=='file'">
                        <strong>File Name:&nbsp;</strong>
                        <a :href="active[0].downloadURL" target="_blank">{{active[0].fileName}}</a>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="active[0].name"
                          label="Name"
                          v-validate="'required'"
                          data-vv-as="name"
                          name="name"
                          :readonly="!isAdmin"
                          :error-messages="errors.collect('name')"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="active[0].description"
                          label="Description"
                          v-validate="'required'"
                          data-vv-as="description"
                          name="description"
                          filled
                          :readonly="!isAdmin"
                          rows="10"
                          :error-messages="errors.collect('description')"
                        ></v-textarea>
                      </v-col>
                      <v-card-actions v-if="isAdmin">
                        <v-spacer></v-spacer>

                        <v-btn color="primary" type="submit">Save</v-btn>
                      </v-card-actions>
                    </v-row>
                  </v-card>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" width="600">
          <folder-form
            v-on:folder-added="folderAdded"
            v-on:folder-closed="folderClosed"
            :folder="vm"
            :parent="active[0]"
            :organisationId="currentOrganisation.id"
          ></folder-form>
        </v-dialog>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as policiesService from "../../services/policiesService";
import folderForm from "./folder-form";

Vue.use(VeeValidate);

export default {
  name: "goals",

  data: () => ({
    vm: {},
    treeview:null,
    dialog: false,
    items: [],
    tree: [],
    valid: true,
    documents: [],
    isAdmin: false,
    editedIndex: -1,
    folder: "",
    description: "",
    loading: false,
    basePath: "",
    active: [],
    avatar: null,
    open: [],
    users: []
    //items: []
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  components: {
    folderForm: folderForm
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },

    selected() {
      if (!this.active.length) return undefined;

      const id = this.active[0];

      return this.users.find(user => user.id === id);
    }
  },
  watch: {
    selected: "randomAvatar"
  },

  methods: {
    init() {
      this.vm = {};
      const me = this;
      if (
        this.user.roles.indexOf("System Administrator") > -1 ||
        this.user.roles.indexOf("Organisation Administrator") > -1
      ) {
        me.isAdmin = true;
      }

      this.basePath = this.currentOrganisation.id + "/policiesAndProcedures/";

      policiesService
        .listByParentId(this.currentOrganisation.id, 1)
        .then(result => {
          if (result.empty) {
            const root = {
              name: "Policies and procedures",
              parentId: 1,
              status: "Active",
              type: "folder",
              children:[]
            };
            policiesService.save(me.currentOrganisation.id, root).then(ref => {
              if (ref != undefined) {
                root.id = ref.id;
              }
              me.items.push(root);
            });
          }
          result.docs.map(doc => {
            const item = doc.data();
            item.id = doc.id;
            me.items.push(item);
          });
        });
    },

    folderClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },
    folderAdded(data) {
      const me = this;
      const folder = {
        status: "Active",
        parentId: me.active[0].id,
        name: data.name,
        description: data.description,
        type: "folder",
        children:[]
      };
      policiesService
        .save(this.currentOrganisation.id, folder)
        .then(ref => {
          if (ref != undefined) {
            folder.id = ref.id;
          }
          if (me.active[0].children == undefined) {
            me.active[0].children = [];
          }
          me.active[0].children.push(folder);
          me.$store.dispatch("setToast", {
            message: "folder saved",
            color: "success"
          });
        })
        .catch(error => {
          console.error("Error saving folder: ", error);
        })
        .finally(() => {
          me.folderClosed();
        });
    },
    validateBeforeSubmit() {
      let me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          me.saveActiveNode();
        } else {
          me.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    saveActiveNode() {
      const me = this;
      if (me.active[0].children == undefined) {
        me.active[0].children = [];
      }
      policiesService
        .save(me.currentOrganisation.id, me.active[0])
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "Save success",
            color: "success"
          });
        });
    },
    policiesAndProceduresUploaded(data) {
      const me = this;

      data.parentId = me.active[0].id;
      data.name = data.originalFileName;

      data.fileName = data.filename;
      // data.description= data.description
      data.type = "file";
      data.status = "Active";
      policiesService
        .save(this.currentOrganisation.id, data)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
            data.id= ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "file saved",
            color: "success"
          });
         
        
          me.active[0].children.push(data)
        })
        .catch(error => {
          console.error("Error saving folder: ", error);
        });
      // this.saveActiveNode();
    },

    deleteNode() {
      if (confirm("Are you sure you want to delete this item?")) {
        this.active[0].status = "Deleted";
        const me = this;
        if (me.active[0].children == undefined) {
          me.active[0].children = [];
        }
        policiesService
          .save(me.currentOrganisation.id, me.active[0])
          .then(ref => {
            if (ref != undefined) {
              me.vm.id = ref.id;
            }
            me.active.splice(0, 1);
            //me.$set(me, "active", Object.assign({}, me.active));
            // me.$set(me, "items", Object.assign({}, me.items));
            me.removeDeleted(me, me.items);
            me.$store.dispatch("setToast", {
              message: "delete success",
              color: "success"
            });
          });
      }
    },
    removeDeleted(me, currentArray) {
      const delItems = [];
      currentArray.forEach(element => {
        if (element.status == "Deleted") {
          delItems.push(element);
        }
        if (
          element.children != undefined &&
          element.children != null &&
          element.children.length > 0
        ) {
          me.removeDeleted(me, element.children);
        }
      });
      delItems.forEach(item => {
        currentArray.splice(currentArray.indexOf(item), 1);
      });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async loadChildren(item) {
      return policiesService
        .listByParentId(this.currentOrganisation.id, item.id)
        .then(result => {
          const foldersArray = [];
          const filesArray = [];
          result.docs.map(doc => {
            const itemData = doc.data();
            itemData.id = doc.id;

            // if (
            //   itemData.children != undefined &&
            //   itemData.children.length == 0
            // ) {
            //   //Known issue with the treeview empty child array cause activeable not to work so need to undefine it
            //   itemData.children = undefined;
            // }
            if (itemData.type == "folder") {
              itemData.children = [];
              foldersArray.push(itemData);
            } else {
              itemData.children = undefined;
              filesArray.push(itemData);
            }
          });
          //  me.items.push(item);
          foldersArray.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          filesArray.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          item.children = foldersArray.concat(filesArray);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


