
   
  <template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-card class="mr-2">
      <v-card-title class="headline grey lighten-2" primary-title>Add Folder</v-card-title>

      <v-card-text>
        <v-text-field
          v-model="vm.name"
          label="Folder name"
          v-validate="'required'"
          data-vv-as="folder"
          name="folder"
          :error-messages="errors.collect('folder')"
        ></v-text-field>

        <v-textarea
          v-model="vm.description"
          label="Folder description"
          rows="1"
          v-validate="'required'"
          data-vv-as="description"
          name="description"
          :error-messages="errors.collect('description')"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
export default {
  name: "notes-form",
  data: () => ({
    duration: "",
    vm: {},
    form: {}
  }),
  watch: {
    folder(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  props: {
    folder: Object,
    organisationId: String,
    parent: Object
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    init() {
      if (this.folder !== undefined) {
        this.vm = this.folder;
      }
    },

    close() {
      this.$emit("folder-closed");
    },

    validateBeforeSubmit() {
      let me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          me.$emit("folder-added", me.vm);
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

